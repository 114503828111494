import React, { useState } from "react";
import SideNavBar from "../components/SideNavBar";

function SideNavBarScreen() {
  // state

  const [indexnumber, setIndexNumber] = useState(0);

  // function
  const handleActive = (index: number) => {
    setIndexNumber(index);
  };

  return (
    <>
      <SideNavBar indexnumber={indexnumber} handleActive={handleActive} />
    </>
  );
}

export default SideNavBarScreen;
