import React from "react";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import ButtonLoading from "./common/ButtonLoading";

type Props = {
  state: any;
  setOpenVouchScreen: React.Dispatch<React.SetStateAction<Boolean>>;
  setOpenOtpScreen: React.Dispatch<React.SetStateAction<Boolean>>;
  openOtpScreen: Boolean;
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  inputs: React.RefObject<HTMLInputElement>[];
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  setContinueBtn: React.Dispatch<React.SetStateAction<Boolean>>;
  continueBtn: Boolean;
  fetchVouchDetail: () => Promise<void>;
  apiMessage: string;
  response: boolean;
  fetchRedeemDiscount: () => Promise<void>;
  openRedeem: boolean;
  redeemResponse: any;
  openRedeemPage: boolean;
  buttonLoader: boolean;
};

function VouchComponent(props: Props) {
  return (
    <>
      <div className="container vouch">
        <RxCrossCircled
          className="svg"
          onClick={() => {
            props.setOpenVouchScreen(false);
          }}
        />
        {props.state.item.isVouch === false && props.state ? (
          <h2>Congratulation</h2>
        ) : (
          <h2>Vouch365</h2>
        )}

        <div className="Vouch-description">
          <div className="border-top"></div>
          {props.openOtpScreen ? (
            <>
              {props.response ? (
                <>
                  {props.openRedeemPage ? (
                    <>
                      <div className="Vouch-detail">
                        <p>{props.apiMessage}</p>
                        <span>
                          <>{props.redeemResponse.results.ref_code}</>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Vouch-detail">
                        <p>{props.apiMessage}</p>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="vouch-code">
                    <p>Please ask merchant to enter Vouch365 code</p>
                    <div className="number-field-otp">
                      <input
                        type="tel"
                        name="one"
                        maxLength={1}
                        ref={props.inputs[0]}
                        value={props.otp.one}
                        onChange={(e) => props.handleChange(e, 0)}
                        onKeyDown={(e) => props.handleBackspace(e, 0)}
                        autoFocus
                      />
                      <input
                        type="tel"
                        name="two"
                        maxLength={1}
                        ref={props.inputs[1]}
                        value={props.otp.two}
                        onChange={(e) => props.handleChange(e, 1)}
                        onKeyDown={(e) => props.handleBackspace(e, 1)}
                      />
                      <input
                        type="tel"
                        name="three"
                        maxLength={1}
                        ref={props.inputs[2]}
                        value={props.otp.three}
                        onChange={(e) => props.handleChange(e, 2)}
                        onKeyDown={(e) => props.handleBackspace(e, 2)}
                      />
                      <input
                        type="tel"
                        name="four"
                        maxLength={1}
                        ref={props.inputs[3]}
                        value={props.otp.four}
                        onChange={(e) => {
                          props.handleChange(e, 3);
                          props.setContinueBtn(true);
                        }}
                        onKeyDown={(e) => props.handleBackspace(e, 3)}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {props.state.item.isVouch === false && props.state ? (
                <>
                  {props.openRedeem ? (
                    <>
                      <div className="Vouch-detail">
                        <p>{props.apiMessage}</p>
                        <span>
                          <>{props.redeemResponse.results.voucher}</>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="Vouch-detail">
                        <p>{props.state.item.businessName}</p>
                        <span>
                          <>{props.state.item.description}</>
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="Vouch-detail">
                  <p>
                    You and your guest are invited To enjoy one complimentary
                    Sundae when an Sundae is Purchased * Dine in, Takeaway Only
                    2 vouchers at a time *
                  </p>
                  <span>
                    Saving:
                    {props.state.item.save ? (
                      <>{props.state.item.save}</>
                    ) : (
                      <>{props.state.item.discountedPrice}</>
                    )}{" "}
                  </span>
                </div>
              )}
            </>
          )}

          <div className="border-bottom"></div>
        </div>

        {props.continueBtn ? (
          <>
            {props.response ? (
              <>
                <span
                  className="btn"
                  onClick={() => {
                    props.setOpenVouchScreen(false);
                  }}
                >
                  Cancel
                </span>
              </>
            ) : (
              <>
                <span
                  className="btn"
                  onClick={() => {
                    props.fetchVouchDetail();
                  }}
                >
                  {props.buttonLoader ? <ButtonLoading /> : <>Continue</>}
                </span>
              </>
            )}
          </>
        ) : (
          <>
            {props.state.item.isVouch === false && props.state ? (
              <>
                {props.openRedeem ? (
                  <>
                    <span
                      className={"btn"}
                      onClick={() => {
                        props.setOpenVouchScreen(false);
                      }}
                    >
                      Okay
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className={"btn"}
                      onClick={() => {
                        props.fetchRedeemDiscount();
                      }}
                    >
                      Get Discount
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                {
                  <span
                    className={props.openOtpScreen ? "btn2" : "btn"}
                    onClick={() => {
                      props.setOpenOtpScreen(true);
                    }}
                  >
                    {props.openOtpScreen ? <>Continue</> : <>Redeem</>}
                  </span>
                }
              </>
            )}
          </>
        )}
        <p>
          Offers are subject to <span>Rules of use Review Rule</span> of use
        </p>
      </div>
      <div className="overlay"></div>
    </>
  );
}

export default VouchComponent;
