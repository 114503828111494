import React from "react";
type Props = {
  DealListName: string[];
  buttonClick: (index: number) => void;

  dealsName: number;
};
function PopularDeals(props: Props) {
  return (
    <>
      <div className="container Popular-Deals">
        <h2>Popular Deals</h2>
        <ul>
          {props.DealListName.map((item: any, index: number | any) => (
            <li
              onClick={() => {
                props.buttonClick(index);
              }}
              className={props.dealsName === index ? "active" : ""}
              key={index}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default PopularDeals;
