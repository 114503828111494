import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import img from "../assets/navbarsidepic.webp";
import AppContext from "../contexts/AppContext";

const navbarList = ["Home", "Food", "Online Stores", "Lifestyle"];

type Props = {
  indexnumber: number;
  handleActive: (index: number) => void;
};

function SideNavBar(props: Props) {
  const { navigationDetail, setNavigateid } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <>
      <div className="container side-navbar">
        <RxCrossCircled
          className="svg"
          onClick={() => {
            navigate(-1);
          }}
        />
        <nav>
          <ul>
            {navbarList.map((list: any, index: number) => (
              <li
                key={index}
                onClick={() => {
                  props.handleActive(index);
                  if (index === 0) {
                    navigate("/");
                  } else {
                    navigate("/fulldesalcard");
                  }

                  setNavigateid(index);
                }}
                className={navigationDetail === index ? "active" : ""}
              >
                {list}
              </li>
            ))}
            <li></li>
          </ul>
        </nav>
        <div className="img">
          <img src={img} alt="" />
        </div>
      </div>
    </>
  );
}

export default SideNavBar;
