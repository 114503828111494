import React, { useState, useEffect, useContext } from "react";
import AppContext from "../contexts/AppContext";
import { fetchApiGet } from "../utils/FetchApi";
import { Apiname } from "../constant/ApiName";
import Header from "../components/Header";

type Props = {
  token: string | null;
};

function HeaderScreen(props: Props) {
  // useState

  const { citiesData, setCity, cityId } = useContext(AppContext);
  // useEffect

  useEffect(() => {
    if (citiesData?.length === undefined) {
      fetchCities();
    }
  }, [props.token]);

  // var

  // Api Call
  const fetchCities = async () => {
    const result = await fetchApiGet(props.token, Apiname.cities);
    if (result.status === 200) {
      setCity(result.results[0].cityId, result.results);
    } else {
    }
  };
  // function
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cityId = e.target.value;

    setCity(cityId, citiesData);
  };

  return (
    <>
      {citiesData?.length > 0 ? (
        <Header
          handleDropdownChange={handleDropdownChange}
          citiesData={citiesData}
          cityId={cityId}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default HeaderScreen;
