import React from "react";
import { Routes, Route } from "react-router-dom";
import DiscountDetailScreen from "./screen/DiscountDetailScreen";
import DashboardScreen from "./screen/DashboardScreen";
import SideNavBarScreen from "./screen/SideNavBarScreen";
import FullDealsCardScreen from "./screen/FullDealsCardScreen";

type Props = {
  token: string | null;
};

function AllRoutes(props: Props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardScreen token={props.token} />} />
        <Route
          path="/dealdetail"
          element={<DiscountDetailScreen token={props.token} />}
        />
        <Route path="/sidenavbar" element={<SideNavBarScreen />} />
        <Route
          path="/fulldesalcard"
          element={<FullDealsCardScreen token={props.token} />}
        />
      </Routes>
    </>
  );
}

export default AllRoutes;
