import { createContext } from "react";

interface AppContext {
  vouchId: string;
  setVouch: (id: string) => void;
  navigationDetail: number;
  setNavigateid: (id: number) => void;
  dealsName: number;
  setName: (name: number) => void;
  cityId: string;
  citiesData: any;
  setCity: (id: string, data: any) => void;
  userMsisdn: string;
  authCode: string;
}

const AppContext = createContext<AppContext>({
  vouchId: "",
  navigationDetail: 0,
  dealsName: 0,
  cityId: "",
  citiesData: [{}],
  userMsisdn: "",
  authCode: "",
  setVouch: () => {},
  setNavigateid: () => {},
  setName: () => {},
  setCity: () => {},
});

export default AppContext;
