import React, { useState, useContext, useEffect } from "react";
import { Apiname } from "../constant/ApiName";
import { fetchApiGet } from "../utils/FetchApi";
import AppContext from "../contexts/AppContext";
import HeaderScreen from "./HeaderScreen";
import FullDealsCard from "../components/FullDealsCard";
import Loader from "../components/common/Loader";
import BackButton from "../components/common/BackButton";

type Props = {
  token: string | null;
};

function FullDealsCardScreen(props: Props) {
  // state
  const [loading, setLoading] = useState(false);
  const [dealsDetails, setDealsDetails] = useState<any>([]);
  const [slicelenght, setSlicelength] = useState(5);
  const [title, setTile] = useState("");
  const { navigationDetail, cityId } = useContext(AppContext);
  const [loadeMoreBtn, setLoadMoreBtn] = useState(false);

  //  useEffect
  useEffect(() => {
    fetchCardDate();
  }, [navigationDetail, cityId]);

  // Api
  const fetchCardDate = async () => {
    setLoading(true);
    let endpoint = "";
    switch (navigationDetail) {
      case 1:
        endpoint = Apiname.fooddealsall + cityId;
        setTile("Food Deals");
        break;
      case 2:
        endpoint = Apiname.onlinestoreall + cityId;
        setTile("Online Store");
        break;
      case 3:
        endpoint = Apiname.lifestyleall + cityId;
        setTile("LifeStyle Deals");
        break;
    }
    let response = await fetchApiGet(props.token, endpoint);
    if (response.status === 200) {
      setDealsDetails(response.results);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  // function
  const loadMore = (arr: any, length: number) => {
    if (length >= arr.length) {
      setLoadMoreBtn(false);
    } else {
      setLoadMoreBtn(true);
    }
    return arr.slice(0, length);
  };

  return (
    <>
      <HeaderScreen token={props.token} />
      {dealsDetails.length > 0 ? (
        <>
          <FullDealsCard
            dealsDetails={dealsDetails}
            loadMore={loadMore}
            setSlicelength={setSlicelength}
            slicelenght={slicelenght}
            loading={loading}
            title={title}
            loadeMoreBtn={loadeMoreBtn}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default FullDealsCardScreen;
