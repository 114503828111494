import React, { useState, useEffect, useContext } from "react";
import AppContext from "../contexts/AppContext";
import ItemCard from "../components/ItemCard";
import { Apiname } from "../constant/ApiName";
import { fetchApiGet } from "../utils/FetchApi";

type Props = {
  token: string | null;
};

function ItemCardScreen(props: Props) {
  // state
  const [fooddealsData, setFoodDealsData] = useState<any>([]);
  const [onlineStoreDealsData, setOnlineStoreDealsData] = useState<any>([]);
  const [lifestyledealsdata, setLifestyledealsdata] = useState<any>([]);
  const [listtoDisplay, setListToDisplay] = useState<string>(
    "onlineStoreDealsData"
  );
  const [loading, setLoading] = useState(false);
  const { cityId, dealsName } = useContext(AppContext);
  const [slicelenght, setSlicelength] = useState(5);
  const [loadeMoreBtn, setLoadMoreBtn] = useState(false);

  // useEffect
  useEffect(() => {
    if (cityId && dealsName === 0) {
      fetchFoodDeals();
      setListToDisplay("foodDeals");
    } else if (cityId && dealsName === 1) {
      fetchOnlineStores();
      setListToDisplay("onlineStoreDealsData");
    } else if (cityId && dealsName === 2) {
      fetchLifeStyleDeal();
      setListToDisplay("lifestyledealsdata");
    } else {
      setLoading(true);
      setListToDisplay("foodDeals");
    }
  }, [cityId, dealsName]);

  // var

  // Api calls
  const fetchFoodDeals = async () => {
    setLoading(true);
    try {
      let endPoint = Apiname.fooddealsdashboard + cityId;
      let response = await fetchApiGet(props.token, endPoint);
      if (response.status === 200) {
        setFoodDealsData(response.results);
        setLoading(false);
        setSlicelength(5);
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };
  const fetchOnlineStores = async () => {
    setLoading(true);
    try {
      let endPoint = Apiname.onlinestoredashboard + cityId;
      let response = await fetchApiGet(props.token, endPoint);
      if (response.status === 200) {
        setOnlineStoreDealsData(response.results);
        setLoading(false);
        setSlicelength(5);
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };
  const fetchLifeStyleDeal = async () => {
    setLoading(true);
    try {
      let endPoint = Apiname.lifestyledashboard + cityId;
      let response = await fetchApiGet(props.token, endPoint);
      if (response.status === 200) {
        setLifestyledealsdata(response.results);
        setLoading(false);
        setSlicelength(5);
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };
  // function
  const loadMore = (arr: any, length: number) => {
    if (length >= arr.length) {
      setLoadMoreBtn(false);
    } else {
      setLoadMoreBtn(true);
    }
    return arr.slice(0, length);
  };

  return (
    <>
      <>
        <ItemCard
          fooddealsData={fooddealsData}
          loading={loading}
          onlineStoreDealsData={onlineStoreDealsData}
          lifestyledealsdata={lifestyledealsdata}
          listtoDisplay={listtoDisplay}
          setListToDisplay={setListToDisplay}
          loadMore={loadMore}
          setSlicelength={setSlicelength}
          slicelenght={slicelenght}
          loadeMoreBtn={loadeMoreBtn}
        />
      </>
    </>
  );
}

export default ItemCardScreen;
