import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import DiscountDetail from "../components/DiscountDetail";
import { fetchApiGet } from "../utils/FetchApi";
import { Apiname } from "../constant/ApiName";
import AppContext from "../contexts/AppContext";
import HeaderScreen from "./HeaderScreen";
import BackButton from "../components/common/BackButton";

declare global {
  interface Window {
    invokeinquirePayment: any;
    getpayMentStatus: any;
  }
}

type Props = {
  token: string | null;
};

function DiscountDetailScreen(props: Props) {
  // State
  const { state } = useLocation();
  const [openVouchScreen, setOpenVouchScreen] = useState<Boolean>(false);
  const [vouchData, setVouchData] = useState<any>([]);
  // const [msisdn, setMsisdn] = useState<any>();
  // const [authCode, setAuthCode] = useState<any>();
  const { setVouch, vouchId, userMsisdn, authCode } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  // const [test, setTest] = useState<any>();
  // const [test4, setTest4] = useState<any>();
  // const [test2, setTest2] = useState<any>();
  // const [test3, setTest3] = useState<any>();
  // const [test5, setTest5] = useState<any>();
  // const [test6, setTest6] = useState<any>();
  // const [test10, setTest10] = useState<any>();
  // const [currentState, setCurrentstate] = useState<any>();
  // useEffect
  useEffect(() => {
    // setMsisdn(localStorage.getItem("msisdn"));
    // setAuthCode(localStorage.getItem("userAuthCode"));

    if (state.item.isVouch) {
      if (vouchId) {
        fetchVouchDetail();
      } else {
        fetchVouchLogin();
      }
    } else {
      fetchFullDetail();
    }
  }, [state, vouchId]);

  // useEffect(() => {
  //   // Load data from storage on component mount
  //   localForage.getItem("myData").then((storedData) => {
  //     if (storedData) {
  //       setTest3(storedData);
  //       setMsisdn(storedData);
  //     }
  //   });
  // }, []);

  // var

  // Api call

  const fetchVouchLogin = async () => {
    try {
      let endPoint = Apiname.vouchLogin + userMsisdn;

      let response = await fetchApiGet(props.token, endPoint);
      setVouch(response.results.user_ID);

      if (response.status === 200) {
      } else {
      }
    } catch (error) {}
  };
  const fetchVouchDetail = async () => {
    try {
      let endPoint =
        Apiname.detail + `?sp_ID=${state.item.id}&user_ID=${vouchId}`;
      let response = await fetchApiGet(props.token, endPoint);

      if (response.status === 200) {
        setVouchData(response.results);
      } else {
      }
    } catch (error) {}
  };
  const fetchFullDetail = async () => {
    try {
      let endpoint = `${Apiname.fulldetail}?os=android&businessDetail=${state.item.businessId}&id=${state.item.id}&type=deals&msisdn=${userMsisdn}`;
      let response = await fetchApiGet(props.token, endpoint);

      if (response.status === 200) {
        setVouchData(response.results);
      } else {
      }
    } catch (error) {}
  };
  const CheckUserStatus = async () => {
    // setCurrentstate("checkUserStatus");
    try {
      let endpoint = `${Apiname.checkUser}?msisdn=${userMsisdn}`;
      let response = await fetchApiGet(props.token, endpoint);

      if (response.status === 200 && response.results.desc === "Active") {
        // setTest2("user is active" + response.results.desc);
        setLoader(false);
        setOpenVouchScreen(true);
      } else {
        // setTest2("user is inactive" + response.results.desc);
        createPayment();
      }
    } catch (error) {
      // setTest2("catch in CheckUserStatus");
      setLoader(false);
    }
  };
  const createPayment = async () => {
    // setCurrentstate("create payemnt");
    try {
      let endpoint = `${Apiname.initiatePayment}?msisdn=${userMsisdn}`;
      let response = await fetchApiGet(props.token, endpoint);

      if (response.status === 200) {
        // setTest3("user is in createpayemnt" + response.status);
        inquirePayment(response.results.paymentTransactionID);
      } else {
        setLoader(false);
        // setTest3("got error in create payment" + response.status);
      }
    } catch (error) {
      // setTest3("catch in create payment");
      setLoader(false);
    }
  };
  const subScribe = async () => {
    // setCurrentstate("subscribe");
    try {
      let endpoint = `${Apiname.subscribe}?msisdn=${userMsisdn}`;
      let response = await fetchApiGet(props.token, endpoint);
      // setTest6("user is in subscription" + response.status);

      if (response.status === 200 && response.results.result === 0) {
        setLoader(false);
        // setTest6("user is in subscription and check is correct");
        setOpenVouchScreen(true);
      } else {
        setLoader(false);
        // setTest6("got error in subscription" + response.status);
      }
    } catch (error) {
      // setTest6("catch in subscription");
    }
  };

  // function
  const checkUser = () => {
    // setCurrentstate("is in check user");
    if (userMsisdn) {
      setLoader(true);
      CheckUserStatus();
      // setTest("msisdn" + userMsisdn);
    } else {
      setLoader(false);
      // setTest("didn't recive msisdn");
    }
  };
  const inquirePayment = (inquirePayment: any) => {
    // setCurrentstate("inquirePayment");
    try {
      checkpaymentStatus();
      window.invokeinquirePayment(inquirePayment);
      // setTest4("user is in inquirePayment");
    } catch (error) {
      // setTest4("user got error in inquirePayment");
      setLoader(false);
    }
  };
  const checkpaymentStatus = () => {
    // setCurrentstate("checkpayment");
    try {
      let res = window.getpayMentStatus();

      if (res.status) {
        // setTest5("user is in payment status" + res.status);
        if (res.status == 9000) {
          subScribe();
        } else {
          setLoader(false);
        }
      } else {
        setTimeout(() => {
          checkpaymentStatus();
        }, 1000);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      {/* {userMsisdn}
      {
        <div>
          <div>{currentState}</div>
          <div>{test}</div>
          <div>{test2}</div>
          <div>{test3}</div>
          <div>{test4}</div>
          <div>{test5}</div>
          <div>{test6}</div>
          <div>{test6}</div>
        </div>
      } */}

      <BackButton />
      <DiscountDetail
        state={state}
        setOpenVouchScreen={setOpenVouchScreen}
        openVouchScreen={openVouchScreen}
        token={props.token}
        vouchData={vouchData}
        msisdn={userMsisdn}
        checkUser={checkUser}
        loader={loader}
      />
    </>
  );
}

export default DiscountDetailScreen;
