import React, { useContext } from "react";
import logo from "../assets/logo.webp";
import selectarrow from "../assets/selectDrop.png";
import { useNavigate } from "react-router-dom";
import AppContext from "../contexts/AppContext";

type Props = {
  citiesData: any;
  cityId: any;
  handleDropdownChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

function Header(props: Props) {
  const navigate = useNavigate();
  const { setNavigateid } = useContext(AppContext);

  return (
    <>
      <header>
        <div className="container">
          <div className="navbar">
            <div
              className="navbar-icone"
              onClick={() => {
                navigate("/sidenavbar");
              }}
            >
              <div className="navbar-bar" />
              <div className="navbar-bar" />
              <div className="navbar-bar" />
            </div>

            <div
              className="Logo"
              onClick={() => {
                setNavigateid(0);
                navigate("/");
              }}
            >
              <img src={logo} alt="logo" />
            </div>
            <div className="selectwrapper">
              <select
                name="city"
                id="cities"
                value={props.cityId}
                onChange={props.handleDropdownChange}
              >
                {props.citiesData?.map((item: any, index: number | any) => (
                  <option key={index} value={item.cityId}>
                    {item.cityName}
                  </option>
                ))}
              </select>
              {/* <div className="arrow">
                <img src={selectarrow} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
