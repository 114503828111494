import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="backArrow"
        onClick={() => {
          navigate(-1);
        }}
      >
        &#10140;
      </div>
    </>
  );
}

export default BackButton;
