import React, { useState, useEffect, useContext } from "react";
import { fetchApiGet } from "../utils/FetchApi";
import { Apiname } from "../constant/ApiName";
import AppContext from "../contexts/AppContext";
import FeatureBrand from "../components/FeatureBrand";

type Props = {
  token: string | null;
};

function FeatureBrandScreen(props: Props) {
  // state
  const [topBrandData, setTopBrandData] = useState<any>();
  const { cityId } = useContext(AppContext);
  // useEffect
  useEffect(() => {
    if (cityId.toString().length > 0) {
      fetchGetTopBrand();
    }
  }, [cityId]);

  // variables
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
  };
  // api call
  const fetchGetTopBrand = async () => {
    let apiName =
      Apiname.topbrand + `?os=android&city_id=${cityId}&nearby=true`;
    try {
      const response = await fetchApiGet(props.token, apiName);

      if (response.status === 200) {
        setTopBrandData(response.results);
      }
    } catch (error) {}
  };

  return (
    <>
      <FeatureBrand settings={settings} topBrandData={topBrandData} />
    </>
  );
}

export default FeatureBrandScreen;
