const baseUrl: string | undefined = process.env.REACT_APP_Base_URL;

export const fetchApiGet = async (token: string | null, endPoint: string) => {
  let response = await fetch(`${baseUrl}${endPoint}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? "Bearer " + token : "",
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};

export const fetchApiPost = async (
  token: string | null,
  endPoint: string,
  data: any
) => {
  let response = await fetch(`${baseUrl}${endPoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};
