import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./common/Loader";

type Props = {
  fooddealsData: any;
  onlineStoreDealsData: any;
  loading: boolean;
  lifestyledealsdata: any;
  listtoDisplay: string;
  setListToDisplay: React.Dispatch<React.SetStateAction<string>>;
  setSlicelength: React.Dispatch<React.SetStateAction<number>>;
  slicelenght: number;
  loadMore: (arr: any, length: number) => any;
  loadeMoreBtn: boolean;
};

function ItemCard(props: Props) {
  const navigate = useNavigate();

  let listdetail = props.fooddealsData;

  if (props.listtoDisplay === "foodDeals") {
    listdetail = props.fooddealsData;
  } else if (props.listtoDisplay === "onlineStoreDealsData") {
    listdetail = props.onlineStoreDealsData;
  } else if (props.listtoDisplay === "lifestyledealsdata") {
    listdetail = props.lifestyledealsdata;
  }
  const list = props.loadMore(listdetail, props.slicelenght);

  return (
    <>
      {props.loading ? (
        <Loader />
      ) : (
        <>
          <div className="container card-list">
            {list.length > 0 &&
              list.map((item: any) => (
                <div
                  className="item-card"
                  id={item.id}
                  key={item.id}
                  onClick={() => {
                    let data = {
                      item: item,
                    };
                    navigate("/dealdetail", {
                      state: data,
                    });
                  }}
                >
                  <div className="card-img" id={item.id}>
                    <img
                      src={item.images.grid}
                      alt=""
                      loading="lazy"
                      width="380"
                      height="234"
                    />
                    <div className="absolute-img" id={item.id}>
                      <img src={item.businessLogo} alt="" loading="lazy" />
                    </div>
                    <div className="flat-off" id={item.id}>
                      <p>{item.title}</p>
                    </div>
                    <div className="gradient-overlay"></div>
                  </div>
                  <div className="card-body" id={item.id}>
                    <div className="location" id={item.id}>
                      <h2>{item.businessName}</h2>
                      <span>Validity</span>
                    </div>
                    <div className="location" id={item.id}>
                      <span>{item.location}</span>
                      <span className="validty">{item.endDate}</span>
                    </div>
                  </div>
                </div>
              ))}
            {props.loadeMoreBtn ? (
              <>
                <span
                  className="btn"
                  onClick={() => {
                    props.setSlicelength(props.slicelenght + 5);
                  }}
                >
                  Load more
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ItemCard;
