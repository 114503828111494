import React, { useState, useContext, useEffect } from "react";
import VouchComponent from "../components/VouchComponent";
import { Apiname } from "../constant/ApiName";
import { fetchApiGet } from "../utils/FetchApi";
import AppContext from "../contexts/AppContext";

type Props = {
  state: any;
  setOpenVouchScreen: React.Dispatch<React.SetStateAction<Boolean>>;
  token: string | null;
  vouchData: any;
  msisdn: string;
};

function VouchComponentScreen(props: Props) {
  // state
  const [openOtpScreen, setOpenOtpScreen] = useState<Boolean>(false);
  const [continueBtn, setContinueBtn] = useState<Boolean>(false);
  const [apiMessage, setApiMessage] = useState("");
  const [response, setResponse] = useState(false);
  const [openRedeem, setOpenRedeem] = useState(false);
  const [openRedeemPage, setOpenRedeemPage] = useState(false);
  const [redeemResponse, setRedResponse] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [inputs, setInputs] = useState<React.RefObject<HTMLInputElement>[]>([
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
  ]);

  const { vouchId } = useContext(AppContext);

  // var
  const checkopiname = otp.one + otp.two + otp.three + otp.four;
  // Api call
  const fetchVouchDetail = async () => {
    setButtonLoader(true);
    try {
      let endPoint = `${Apiname.vouchRedeemDiscount}?pin=${checkopiname}&vc_ID=${props.vouchData[0].vouchersData[0].vc_ID}&user_ID=${vouchId}&sp_ID=${props.vouchData[0].vouchersData[0].sp_ID}&savings=${props.state.item.save}`;

      let response = await fetchApiGet(props.token, endPoint);

      if (response.status === 200 && response.results.status == "successful") {
        setRedResponse(response);
        setApiMessage(response.results.desc);
        setResponse(true);
        setOpenRedeemPage(true);
      } else {
        setApiMessage(response.results.desc);
        setResponse(true);
        setButtonLoader(false);
      }
    } catch (error: any) {}
  };
  const fetchRedeemDiscount = async () => {
    try {
      let endPoint = `${Apiname.redeem}?merchant=0&businessDetail=${props.state.item.businessId}&deal=${props.state.item.id}&msisdn=${props.msisdn}`;
      let response = await fetchApiGet(props.token, endPoint);

      if (response.status === 200 && response.error == false) {
        setOpenRedeem(true);
        setRedResponse(response);
        setApiMessage(response.results.desc);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  // function
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    if (!isNaN(newValue as any) && newValue !== " ") {
      setOtp({
        ...otp,
        [e.target.name]: newValue,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputs[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    }
  };
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setContinueBtn(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputs[index - 1].current as HTMLInputElement).focus();
      }
    }
  };

  return (
    <>
      <VouchComponent
        state={props.state}
        setOpenVouchScreen={props.setOpenVouchScreen}
        setOpenOtpScreen={setOpenOtpScreen}
        openOtpScreen={openOtpScreen}
        otp={otp}
        inputs={inputs}
        handleChange={handleChange}
        handleBackspace={handleBackspace}
        setContinueBtn={setContinueBtn}
        continueBtn={continueBtn}
        fetchVouchDetail={fetchVouchDetail}
        apiMessage={apiMessage}
        response={response}
        fetchRedeemDiscount={fetchRedeemDiscount}
        openRedeem={openRedeem}
        redeemResponse={redeemResponse}
        openRedeemPage={openRedeemPage}
        buttonLoader={buttonLoader}
      />
    </>
  );
}

export default VouchComponentScreen;
