import React, { useState, useEffect } from "react";
import { fetchApiGet, fetchApiPost } from "./utils/FetchApi";
import { Apiname } from "./constant/ApiName";
import AllRoutes from "./AllRoutes";
import AppContext from "./contexts/AppContext";
import localForage from "localforage";

declare global {
  interface Window {
    invokeGetUserAuthCode: any;
    getAuthCodeStatus: any;
    resetAuthCode: any;
  }
}

function App() {
  // useState
  const [token, setToken] = useState<string | null>("");
  const [cityId, setCityId] = useState<string>("");
  const [vouchId, setVouchId] = useState<string>("");
  const [dealsName, setDealsName] = useState<number>(0);
  const [navigationDetail, setNavigationDetail] = useState(0);
  const [citiesData, setCitiesData] = useState<any>();
  // const [desc, setDesc] = useState<any>("checking");
  const [userMsisdn, setMsisdn] = useState<any>("");
  const [authCode, setAuthCode] = useState("");
  const [testName, setTestName] = useState("");
  const [test2, setTest2] = useState("");

  // var

  // useEffect
  useEffect(() => {
    tokenGenerate();
    onGetUserAuthCode();
    // setMsisdn("3455455467");
  }, []);

  const onGetUserAuthCode = () => {
    try {
      checkAuthCode();
      window.invokeGetUserAuthCode();
    } catch (error) {}
  };

  const checkAuthCode = () => {
    try {
      let res = window.getAuthCodeStatus();
      if (res.status) {
        if (res.status.toLowerCase() === "success" && res.authCode) {
          getUserInfo(res.authCode);
        }
      } else {
        setTimeout(() => {
          checkAuthCode();
        }, 1000);
      }
    } catch (error) {}
  };

  // Api Call
  const tokenGenerate = async () => {
    try {
      let data: any = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      const result = await fetchApiPost(null, Apiname.authenticateWeb, data);
      if (result.status === 200) {
        setToken(result.access_token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserInfo = async (userAuthCode: string) => {
    try {
      let endpoint = `${Apiname.inquireUserInfo}?authCode=${userAuthCode}`;

      const response = await fetchApiGet(token, endpoint);
      setTest2(response.status);
      setTestName(response.response);
      if (response.status === 200) {
        setTestName(userAuthCode);
        setTest2(response.results.userMsisdn);
        setMsisdn(response.results.userMsisdn);
        setAuthCode(userAuthCode);
      } else {
        setMsisdn("03446211489");
        setTestName("3455455467");
        setTest2("3455455467");
      }
    } catch (error) {
      setMsisdn("03446211489");
    }
  };
  // const onReset = () => {
  //   let res = window.resetAuthCode();
  //   setAuthCode(res.status + res.authCode);
  // };
  // Function
  const setCity = (id: string, data: any) => {
    setCityId(id);
    setCitiesData(data);
  };
  const setVouch = (id: string) => {
    setVouchId(id);
  };
  const setName = (name: number) => {
    setDealsName(name);
  };
  const setNavigateid = (detail: number) => {
    setNavigationDetail(detail);
  };

  return (
    <>
      {/* <div>
        <div>
          <button onClick={onReset}>reset</button>
        </div>
        <div>
          <button onClick={onGetUserAuthCode}>Click</button>
        </div>
        <div>AuthCode:{authCode}</div>
        <div>Desc:{desc}</div>
      </div> */}

      {/* {userMsisdn}
      {authCode}
      {"this is authCode " + testName}
      {"this is number " + test2} */}

      <AppContext.Provider
        value={{
          cityId,
          citiesData,
          setCity,
          vouchId,
          setVouch,
          dealsName,
          setName,
          navigationDetail,
          setNavigateid,
          userMsisdn,
          authCode,
        }}
      >
        <AllRoutes token={token} />
      </AppContext.Provider>
    </>
  );
}

export default App;
