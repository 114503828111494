import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Props = {
  settings: {
    dots: boolean;
    infinite: boolean;
    speed: number;
    slidesToShow: number;
    slidesToScroll: number;
    arrows: boolean;
  };
  topBrandData: any;
};

function FeatureBrand(props: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <div className="feature-brand">
          <h2>Featured Brands</h2>
          <Slider {...props.settings}>
            {props.topBrandData?.length > 0 &&
              props.topBrandData.map((item: any, index: any) => (
                <div
                  className="feature-card"
                  key={index}
                  onClick={() => {
                    let data = {
                      item: item,
                    };
                    navigate("/dealdetail", {
                      state: data,
                    });
                  }}
                >
                  <div className="feature-card-img">
                    <img src={item.businessLogo} alt="" loading="lazy" />
                  </div>
                  <span>{item.businessName}</span>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default FeatureBrand;
