import React from "react";
import Loader from "../components/common/Loader";
import HeaderScreen from "./HeaderScreen";
import FeatureBrandScreen from "./FeatureBrandScreen";
import PopularDealsScreen from "./PopularDealsScreen";
import ItemCardScreen from "./ItemCardScreen";

type Props = {
  token: string | null;
};
function DashboardScreen(props: Props) {
  return (
    <>
      {props.token ? (
        <>
          <HeaderScreen token={props.token} />
          <FeatureBrandScreen token={props.token} />
          <PopularDealsScreen />
          <ItemCardScreen token={props.token} />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default DashboardScreen;
