import React from "react";

function ButtonLoading() {
  return (
    <>
      <div className="loading-box">
        <div className="inter-load">
          <div className="rect rect1"></div>
          <div className="rect rect2"></div>
          <div className="rect rect3"></div>
          <div className="rect rect4"></div>
          <div className="rect rect5"></div>
        </div>
      </div>
    </>
  );
}

export default ButtonLoading;
