import React, { useContext, useEffect, useState } from "react";
import PopularDeals from "../components/PopularDeals";
import AppContext from "../contexts/AppContext";

const DealListName = ["Food Deals", "Online Stores", "Lifestyle Deals"];

function PopularDealsScreen() {
  const { dealsName, setName } = useContext(AppContext);

  // function
  const buttonClick = (index: number) => {
    setName(index);
  };
  return (
    <>
      <PopularDeals
        DealListName={DealListName}
        buttonClick={buttonClick}
        dealsName={dealsName}
      />
    </>
  );
}

export default PopularDealsScreen;
