export const Apiname = {
  authenticateWeb: "auth/access",
  cities: "v3/cities?os=android",
  fooddealsdashboard: "v3/listing/premium/dashboard?os=android&city_id=",
  onlinestoredashboard: "v3/listing/ecommerce/dashboard?os=android&city_id=",
  lifestyledashboard: "v3/listing/lifestyle/dashboard?os=android&city_id=",
  vouchLogin: "v3/vouch365/login?msisdn=",
  detail: "v3/vouch365/detail",
  topbrand: "v3/listing/topbrand/all",
  vouchRedeemDiscount: "v3/vouch365/redeem",
  fooddealsall: "v3/listing/premium/all?os=android&city_id=",
  onlinestoreall: "v3/listing/ecommerce/all?os=android&city_id=",
  lifestyleall: "v3/listing/lifestyle/all?os=android&city_id=",
  fulldetail: "v3/fullDetails",
  redeem: "v3/redeemdiscount",
  inquireUserInfo: "v3/es/inquireUserInfo",
  initiatePayment: "v3/es/createpayment",
  inquirePayment: "v3/es/inquirePayment",
  subscribe: "v3/es/subscribe",
  checkUser: "v3/es/checkUser",
};
