import React from "react";
import VouchComponentScreen from "../screen/VouchComponentScreen";
import ButtonLoading from "./common/ButtonLoading";

type Props = {
  state: any;
  setOpenVouchScreen: React.Dispatch<React.SetStateAction<Boolean>>;
  openVouchScreen: Boolean;
  token: string | null;
  vouchData: any;
  msisdn: string;
  checkUser: () => void;
  loader: Boolean;
};

function DiscountDetail(props: Props) {
  return (
    <>
      <div className="container discount-detail">
        <div className="dicountDetailimg">
          <img src={props.state.item.images.grid} alt="" />
        </div>
        <div className="discountdetailsbody">
          <h2>{props.state.item.title}</h2>
          <span>{props.state.item.businessName}</span>
          <p className="boderBtm ">{props.state.item.description}</p>
          <p className="boderBtm " style={{ padding: "0 0 10px" }}>
            Deal validity:
            <span>{props.state.item.endDate}</span>
          </p>
          {/* <div className="selectoutlet">
            <p>Select outlet to avail discount</p>
          </div> */}

          <span
            className="btn"
            onClick={() => {
              props.checkUser();
              console.log();
            }}
          >
            {props.loader ? <ButtonLoading /> : <> Get Discount</>}
          </span>
        </div>
      </div>
      {props.openVouchScreen ? (
        <VouchComponentScreen
          state={props.state}
          setOpenVouchScreen={props.setOpenVouchScreen}
          token={props.token}
          vouchData={props.vouchData}
          msisdn={props.msisdn}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default DiscountDetail;
